<template>
  <div>
    <div class="cameras-div">
      <template v-for="(item, index) in cameras">
        <div :key="`camera_${index}`" :id="`container${index}`" class="camera-div">

          <div class="video-title">{{ item.name }}</div>

          <video :id="`remoteVideo${index}`" playsinline autoplay controls></video>
          <audio :id="`remoteAudio${index}`" autoplay></audio>

          <div class="box">
            <div style="margin-bottom: 10px;height: 28px;line-height: 28px;">
              <span @click="playOrPause(item.devId, item)">{{ item.pc ? "断开" : "连接" }}</span>
              <span @click="snapshotAndSave(item.devId)" style="margin: 0">截图</span>
            </div>

            <div v-show="false">
              <el-button-group class="control-btns2">
                <el-button type="primary" class="" @click="ptzControl($event, 6, item.devId)"><span>左</span></el-button>
                <el-button type="primary" class="" @click="ptzControl($event, 0, item.devId)"><span>上</span></el-button>
                <el-button type="primary" class="" @click="ptzControl($event, 4, item.devId)"><span>下</span></el-button>
                <el-button type="primary" class="" @click="ptzControl($event, 2, item.devId)"><span>右</span></el-button>
              </el-button-group>
            </div>
          </div>
        </div>
      </template>

      <canvas ref="canvas" style="display: none"></canvas>
    </div>
  </div>
</template>

<script>
import './index.scss'
import {debounce, fmtDate} from 'j-utils_jerrychen';
import {CameraApi} from "@/api/camera";
import CryptoJS from 'crypto-js';

export default {
  name: "CameraIndex",
  props: {
    dataList: {
      type: Array
    }
  },
  computed: {
    listenWebsocket() {
      return this.$store.state.websocket.message
    },
    cameras() {
      let arr = [];
      this.dataList.forEach(item => {
        arr.push({
          name: item.name,
          devId: item.devId,
          startTime: null,

          iceServers: null,
          configuration: null,
          pc: null,
          localStream: null,
          gAgentId: null,
          gSessionId: null,

          remoteVideo: null,
          remoteAudio: null,

          buffer: null,
          mediaRecorder: null
        })
      })
      return arr
    }
  },
  watch: {
    listenWebsocket: function (evt) {
      // console.log(evt, 'evt');
      this.parseResponse(JSON.parse(evt))
      // const newmsg = JSON.parse(newstr);
      /*if (newstr && newstr.indexOf('[') == -1) {
        //收到信消息
        this.$notification.open({
          message: newmsg.objName,
          description: newmsg.title
        });
        //刷新消息
        this.$store.dispatch('websocket/WEBSOCKET_SEND', 'refreshMessage');
      } else {
        this.noticeData = [];
        this.messageData = [];
        this.msgCount = newmsg.length;
        for (var i = 0; i < newmsg.length; i++) {
          var m = newmsg[i];
          m.createDate = m.objName + ' · ' + this.nowTimeAgo(m.createDate);
          if (m.messageType.indexOf('Notice')!=-1) {
            this.noticeData.push(m);
          }
          if (m.messageType.indexOf('Message')!=-1) {
            this.messageData.push(m);
          }
        }
      }*/
    }
  },
  data() {
    let vm = this;
    return {
      encodeKey: vm.encodeStr()
    }
  },
  mounted() {
    if (!this.$store.state.websocket.websock) {
      this.$store.dispatch('websocket/WEBSOCKET_INIT', this.$local.cameraUrl())
    }
  },
  methods: {
    beforeunload(e) {
      // alert("刷新会失效")
    },
    ptzControl:
        debounce((e, direction, devId) => {
          CameraApi.changeDirection({id: devId, direction})
        }, 500)
    ,
    encodeStr() {
      const word = fmtDate(new Date(), "yyyy.MM.dd")

      const srcs = CryptoJS.enc.Utf8.parse(word);
      const key = CryptoJS.enc.Utf8.parse("jerrychenyshinfo");
      const iv = CryptoJS.enc.Utf8.parse("yshinfojerrychen");
      const encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.AnsiX923
      });
      return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    },
    play(camera) {
      const blob = new Blob(camera.buffer, {type: 'video/webm'});
      this.$refs['rpvideo'].src = window.URL.createObjectURL(blob);
      this.$refs['rpvideo'].srcObject = null;
      this.$refs['rpvideo'].controls = true;
      // this.$refs['rpvideo'].play();
    },
    drawFrame() {
      const videoplayer = this.remoteVideo;
      const width = this.$refs['canvas'].width;
      // const width = (videoplayer.clientWidth * videoplayer.clientHeight) / this.$refs['canvas'].clientWidth;
      const height = width * videoplayer.clientHeight / videoplayer.clientWidth;
      this.cc.clearRect(0, 0, width, height)
      this.cc.drawImage(videoplayer, 0, 0, width, height)
      this.frameId = window.requestAnimationFrame(this.drawFrame)
    },
    record(camera, event) {
      const text = event.currentTarget.innerText;
      if (text === 'record') {
        camera.buffer = [];
        event.currentTarget.innerText = 'stop record';
        const cc = this.$refs['canvas'].getContext('2d');
        // cc.fillStyle = "deepskyblue";
        // cc.fillRect(0,0,this.$refs['canvas'].width, this.$refs['canvas'].height)
        camera.mediaRecorder = new MediaRecorder(this.$refs['canvas'].captureStream(60),
            {mimeType: 'video/webm;codecs=vp8'})
        camera.mediaRecorder.videoBitPerSecond = 4194304;
        camera.mediaRecorder.ondataavailable = (e) => {
          if (e && e.data && e.data.size > 0) {
            camera.buffer.push(e.data)
          }
        };
        camera.mediaRecorder.start(10);
        this.remoteVideo = camera.remoteVideo;
        this.cc = cc;
        this.drawFrame()

        /*camera.buffer = []
        const opts = {mimeType: 'video/webm;codecs=vp8'}
        camera.mediaRecorder = new MediaRecorder(camera.localStream, opts);
        camera.mediaRecorder.ondataavailable = (e) => {
          if (e && e.data && e.data.size > 0) {
            camera.buffer.push(e.data)
          }
        };
        camera.mediaRecorder.start(10);*/
      } else if (text === 'stop record') {
        event.currentTarget.innerText = 'record';
        camera.mediaRecorder.stop();
        cancelAnimationFrame(this.frameId);
        const blob = new Blob(camera.buffer, {type: 'video/webm'});
        this.$refs['rpvideo'].src = window.URL.createObjectURL(blob);
        this.$refs['rpvideo'].srcObject = null;
        this.$refs['rpvideo'].controls = true;

        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = new Date().getTime();
        a.click();
        a.remove();

      }
    },
    snapshot() {
      const videoplayer = this.cameras[0].remoteVideo;
      this.$refs['canvas'].width = 500
      this.$refs['canvas'].height = 500 * videoplayer.clientHeight / videoplayer.clientWidth
      this.$refs['canvas']
          .getContext('2d')
          .drawImage(videoplayer, 0, 0, videoplayer.clientWidth, videoplayer.clientHeight)
    },
    snapshotAndSave(devId) {
      const videoplayer = this.findByDevId(devId).remoteVideo;
      this.$refs['canvas'].width = 500
      this.$refs['canvas'].height = 500 * videoplayer.clientHeight / videoplayer.clientWidth
      this.$refs['canvas']
          .getContext('2d')
          .drawImage(videoplayer, 0, 0, videoplayer.clientWidth, videoplayer.clientHeight)
      // let imgData = new Image();
      // imgData.src = this.$refs['canvas'].toDataURL("image/png");
      let a = document.createElement("a")
      a.href = this.$refs['canvas'].toDataURL("image/png");
      a.download = new Date().getTime();
      a.click();
      a.remove();
    },
    // 连接WebSocket服务
    async connectWS(wsLink) {
      const vm = this;
      if (this.ws == null) {
        this.ws = new WebSocket(wsLink, "json")
        console.log('***CREATED WEBSOCKET')
      }
      const ws = this.ws

      // 生成网页对应全局唯一agent标识符
      this.gAgentId = this.uuid()
      ws.onopen = function (evt) {
        console.log('***ONOPEN')
        ws.send(JSON.stringify({msg: "hello", key: vm.encodeKey}))
        console.log(ws)
      }

      ws.onerror = function (err) {
        console.err(err)
      }

      // 注册WebSocket的消息回调处理函数
      ws.onmessage = function (evt) {
        console.log('***ONMESSAGE')
        vm.parseResponse(JSON.parse(evt.data))
      }
    },

    findByDevId(devId) {
      return this.cameras.filter(item => item.devId === devId)[0]
    },

    // WebSocket消息回调处理函数
    parseResponse(response) {
      const vm = this
      // const pc = this.pc
      console.log("Response:", response)

      if (response.success !== true) {
        console.log("response not success")
        return
      }

      let camera = vm.findByDevId(response.devId)

      if (response.type === 'answer') {
        console.log("get answer from OpenAPI")

        let answer = {}
        answer["type"] = "answer"
        answer["sdp"] = response.payload

        if (camera !== null && camera.pc) {
          camera.pc.setRemoteDescription(answer)
        }
      } else if (response.type === 'candidate') {
        console.log("get candidate from OpenAPI")

        let candidate = {}
        candidate["candidate"] = response.payload
        candidate["sdpMid"] = '0'
        candidate["sdpMLineIndex"] = 0

        if (camera !== null && camera.pc) {
          camera.pc.addIceCandidate(candidate).catch(e => {
            console.log("addIceCandidate fail: " + e.name)
          })
        }
      } else if (response.type === 'disconnect') {
        console.log("get disconnect from OpenAPI")

        this.sendDisconnect(camera.devId)
      } else if (response.type === 'webrtcConfigs') {
        console.log("get iceServers from OpenAPI")
        console.log(response.payload)
        console.log(JSON.parse(response.payload))

        if (camera !== null) {
          camera.iceServers = JSON.parse(response.payload)
        }
        // this.iceServers = JSON.parse(response.payload)

        this.call(camera)
      }
    },

    // 启动webRTC业务
    async call(camera) {
      console.log('call')

      camera.startTime = window.performance.now()

      const configuration = {
        "iceServers": camera.iceServers
      }

      console.log('RTCPeerConnection configuration:', configuration)

      const vm = this;
      camera.pc = new RTCPeerConnection(configuration)
      vm.$forceUpdate();
      console.log('Created remote peer connection object pc')
      const pc = camera.pc

      pc.addEventListener('icecandidate', e => vm.onIceCandidate(camera, e))
      pc.addEventListener('iceconnectionstatechange', e => vm.onIceStateChange(camera, e))
      pc.addEventListener('track', e => vm.gotRemoteStream(camera, e))

      pc.addTransceiver('audio', {'direction': 'recvonly'})
      pc.addTransceiver('video', {'direction': 'recvonly'})

      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
        console.log('Received local stream')
        camera.localStream = stream
      } catch (e) {
        alert(`getUserMedia() error: ${e.name}`)
      }

      camera.localStream.getTracks().forEach(track => {
        if (track.kind !== 'audio') {
          pc.addTrack(track, camera.localStream);
          console.log('==============', track, track.kind);
        }
      })
      console.log('Added local stream to pc')

      // Since the remote side has no media stream, we need to pass in the right constraints,
      // in order for it to accept the incoming offer with audio and video.
      try {
        console.log('pc createOffer start')
        const offer = await camera.pc.createOffer({
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1
        })
        console.log('Original Offer:', offer)
        await vm.onCreateOfferSuccess(camera, offer)
      } catch (e) {
        vm.onCreateSessionDescriptionError(e)
      }
    },

    onCreateSessionDescriptionError(error) {
      console.log(`Failed to create session description: ${error.toString()}`)
    },

    // PeerConnection生成offer成功，发送到WebSocket服务
    async onCreateOfferSuccess(camera, desc) {
      console.log(`Offer from pc`)
      console.log(JSON.stringify(desc))
      console.log('pc setLocalDescription start')

      try {
        await camera.pc.setLocalDescription(desc)
        this.onSetLocalSuccess(this.pc)
      } catch (e) {
        this.onSetSessionDescriptionError()
      }

      this.sendOffer(desc.sdp, camera.devId)
    },
    async sendFetchWebRTCConfigs(devId = null) {
      try {
        console.log(this.ws)
        this.sendWS("webRTCConfigs", "", devId)
      } catch (e) {
        console.error(e)
        console.log("sendUpdateWebRTCConfigs fail: " + e.name)
      }
    },
    // 发送数据到WebSocket服务
    sendWS(type, payload, devId = null) {
      console.log('***SEND')

      let data = {}

      data["agentId"] = this.gAgentId
      data["sessionId"] = this.gSessionId
      data["type"] = type
      data["payload"] = payload

      data["devId"] = devId
      data["key"] = this.encodeKey
      console.log("devId", devId);
      console.log(JSON.stringify(data))

      // console.log(this.ws)


      this.$store.dispatch('websocket/WEBSOCKET_SEND', JSON.stringify(data))
      // this.ws.send(JSON.stringify(data))

    },

    async sendOffer(sdp, devId) {
      // shorter sdp, remove a=extmap... line, device ONLY allow 8KB json payload
      sdp = sdp.replace(/\r\na=extmap[^\r\n]*/g, '')

      console.log("send offer: " + sdp)

      try {
        this.sendWS("offer", sdp, devId)
      } catch (e) {
        console.log("send offer via WebSocket fail: " + e.name)
      }
    },

    async sendCandidate(candidate, devId) {
      try {
        this.sendWS("candidate", candidate, devId)
      } catch (e) {
        console.log("sendCandidate fail: " + e.name)
      }
    },
    async sendDisconnect(devId) {
      console.log("hangup")

      let vm = this;
      let camera = this.findByDevId(devId)
      if (camera.pc) {
        camera.pc.close()
        camera.pc = null
        vm.$forceUpdate();
      }

      try {
        this.sendWS("disconnect", "", camera.devId)
      } catch (e) {
        console.log("hangup the call fail: " + e.name)
      }
    },
    onSetLocalSuccess(pc) {
      console.log(`setLocalDescription complete`)
    },
    onSetSessionDescriptionError(error) {
      console.log(`Failed to set session description: ${error.toString()}`)
    },
    // 获取对端音视频流，绑定到网页上的播放控件
    gotRemoteStream(camera, e) {
      const vm = this
      console.log('Debug........ ', e.track.kind)

      if (e.track.kind === 'audio') {
        if (camera.remoteAudio == null) {
          const index = vm.cameras.findIndex(item => item.devId === camera.devId);
          camera.remoteAudio = document.getElementById(`remoteAudio${index}`)
        }
        camera.remoteAudio.srcObject = e.streams[0]
      } else if (e.track.kind === 'video') {
        if (camera.remoteVideo == null) {
          const index = vm.cameras.findIndex(item => item.devId === camera.devId);
          camera.remoteVideo = document.getElementById(`remoteVideo${index}`)
        }
        camera.remoteVideo.srcObject = e.streams[0]
      }
    },
    // 采集到本地candidate候选地址，发送到WebSocket服务
    async onIceCandidate(camera, event) {
      console.log(`ICE candidate:\n${event.candidate ? event.candidate.candidate : '(null)'}`)

      try {
        if (event.candidate != null) {
          this.sendCandidate("a=" + event.candidate.candidate, camera.devId)
        } else {
          this.sendCandidate("")
        }
      } catch (e) {
        this.onAddIceCandidateError(camera.pc, e)
      }
    },

    onAddIceCandidateError(pc, error) {
      console.log(`failed to add ICE Candidate: ${error.toString()}`)
    },

    // ICE连接状态变更处理函数
    onIceStateChange(camera, event) {
      if (camera.pc) {
        console.log(`ICE state: ${camera.pc.iceConnectionState}`)
        console.log('ICE state change event: ', event)

        if (camera.pc.iceConnectionState === 'connected') {
          console.log("webRTC connected")
        }
      }
    },
    // 关闭到WebSocket服务的连接
    closeWS() {
      console.log('***CLOSE')
      this.ws.close()
    },

    playOrPause(devId, item) {
      if (!item.pc) {
        this.fetchWebRTCConfigs(devId)
      } else {
        this.sendDisconnect(devId)
      }
    },

    // 每次点击Call时都拉取最新的webrtc configs，并生成新会话的id
    async fetchWebRTCConfigs(devId = null) {
      console.log("fetch webrtc configs")
      this.gSessionId = this.uuid()
      this.sendFetchWebRTCConfigs(devId)
    },

    uuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      )
    },
  }
}
</script>

<style scoped>

</style>
