import axios from "axios";

let request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  responseType: 'json'
})
export const CameraApi = {
  getDevices: () => {
    return request.post(`/camera/unlogin/list`)
  },
  getWsLink: () => {
    return request.post(`/camera/unlogin/ws-link`)
  },
  changeDirection: (data = {}) => {
    return request.post('/camera/unlogin/turn', data)
  },
}
